<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title> Выплаты веб-мастерам </b-card-title>
        <div>
          <b-button
            @click="modalsVisibility.payouts = true"
            class="ml-1"
            pill
            variant="primary"
            >Выплатить</b-button
          >
        </div>
      </b-card-header>

      <b-skeleton-table
        v-if="is_loading"
        animation="fade"
        :columns="7"
        :rows="5"
      >
      </b-skeleton-table>

      <b-table-simple v-else class="font-small-3 text-black" responsive>
        <b-thead>
          <b-tr>
            <b-td>txid</b-td>
            <b-td>Продукт</b-td>
            <b-td>Заявка</b-td>
            <b-td>Веб-мастер</b-td>
            <b-td>Сумма</b-td>
            <b-td>Дата выплаты</b-td>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in items" :key="index">
            <b-td class="text-black font-weight-bolder">{{ item.id }}</b-td>
            <b-td class="text-black"
              ><p class="mb-0">{{ item.offer.title }}</p>
              <span class="fs-12 text-muted"># {{ item.offer.id }}</span></b-td
            >
            <b-td class="text-black"> #{{ item.order.ouid }} </b-td>
            <b-td class="text-black">
              <b-link
                @click="
                  $router.push({
                    name: 'admin-users-view',
                    params: { id: item.user.id },
                  })
                "
              >
                #{{ item.user.uuid }}
              </b-link></b-td
            >
            <b-td class="text-black"
              >{{ item.amount.formatMoney("", "", "") }} ₽</b-td
            >
            <b-td class="text-black">{{ item.date }} {{ item.time }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-card-body class="d-flex justify-content-between pt-0 pb-0">
        <span class="font-weight-bolder">Всего: {{ total_count }}</span>

        <b-pagination-nav
          :value="current_page"
          :link-gen="linkGen"
          :number-of-pages="last_page"
          use-router
        ></b-pagination-nav>
      </b-card-body>
    </b-card>

    <b-modal
      id="payout-modal"
      v-model="modalsVisibility.payouts"
      centered
      title="Выплата веб-мастеру"
      hide-footer
      modal-class=""
    >
      <payout-modal-content />
    </b-modal>
  </div>
</template>

<script>
import User from "@/modules/user/"

import PayoutModalContent from "./modals/payout";

export default {
  data() {
    return {
      is_loading: true,
      items: [],
      current_page: 0,
      has_more_pages: false,
      last_page: 0,
      total_count: 0,
      categories: [],
      companies: [],
      cities: [],

      modalsVisibility: {
        payouts: false,
      },
    };
  },
  beforeRouteEnter(from, to, next) {
    let perms = User.self.permissions.find(
      (item) => item.registry.key === "transactions"
    );

    if (!perms) {
      next({
        name: "admin-error-access-denied",
        params: {
          module: "transactions",
        },
      });
    }

    if (perms.read === false) {
      next({
        name: "admin-error-access-denied",
        params: {
          module: "transactions",
        },
      });
    }

    next(true);
  },
  methods: {
    linkGen(pageNum) {
      return {
        name: this.$route.name,
        query: {
          page: pageNum,
        },
      };
    },

    get() {
      this.is_loading = true;

      this.$request.get("transactions/list", this.$route.query).then((rsp) => {
        this.items = rsp.items;
        this.total_count = rsp.total_count;
        this.current_page = rsp.current_page;
        this.last_page = rsp.last_page;
        this.is_loading = false;
      });
    },
  },
  components: {
    PayoutModalContent,
  },
  watch: {
    "$route.query": function () {
      this.get();
    },
  },
  mounted() {
    this.get();
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
