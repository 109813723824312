<template>
  <div>
    <div>
      <p class="text-muted" style="font-size: 13px; line-height: 20px">
        Выплата веб-мастеру происходит по конкретной заявке
      </p>
      <p class="text-muted" style="font-size: 13px; line-height: 20px">
        Средства сразу поступают на внутренний баланс веб-мастера на платформе
      </p>
    </div>
    <div class="data-form">
      <b-form-group>
        <span class="text-muted" style="font-size: 12px"
          >Идентификатор заявки</span
        >
        <b-form-input
          v-model="ouid"
          placeholder=""
          type="number"
          @blur="checkOuid"
        />
      </b-form-group>
      <b-form-group>
        <div class="d-flex justify-content-between">
          <div style="flex-grow: 1">
            <span class="text-muted" style="font-size: 12px"
              >Сумма к пополнению</span
            >
            <b-form-input v-model="amount" placeholder="0,00" type="number" />
          </div>
          <div class="mt-auto mb-auto">
            <span class="text-muted">RUB</span>
          </div>
        </div>
      </b-form-group>
    </div>

    <b-button
      @click="payout"
      :disabled="!isCanSend"
      variant="primary"
      block
      size="lg"
      style="border-radius: 16px; margin-top: 22px"
    >
      <div v-if="is_loading" class="d-flex justify-content-center">
        <span class="mt-auto mb-auto ml-1">Отправка...</span>
      </div>
      <span v-else> Выплатить </span>
    </b-button>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      is_loading: false,
      amount: null,
      ouid: null,
      is_valid_ouid: false,
    };
  },
  methods: {
    payout() {
      this.is_loading = true;

      this.$request
        .post("transactions/payout", {
          ouid: this.ouid,
          amount: this.amount,
        })
        .then((rsp) => {
          this.amount = null;
          this.ouid = null;
          this.$toast({
            component: ToastificationContent,
            props: {
              text: `Выплата произведена`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.is_loading = false;
        }).catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: err.response.data.message,
              icon: "CheckCircleIcon",
              variant: "danger",
            },
          });
          this.is_loading = false;
        });
    },
    checkOuid() {
      console.log("check ouid");
    },
  },
  computed: {
    isCanSend() {
      // if (!this.is_valid_ouid) return false;
      if (!this.amount || this.amount < 0) return false;
      if (!this.ouid || this.ouid < 0) return false;
      if (this.is_loading) return false;
      return true;
    },
  },
  watch: {
    ouid: {
      handler(bef) {},
    },
  },
};
</script>

<style></style>
